import React, { useState, useEffect } from "react";

const useAudio = (url) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(true);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.pause();
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, [audio]);

  return [playing, toggle];
};

const Player = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <button
      className="shadow-xl hover:shadow-2xl fixed bottom-24 right-0 z-50 bg-primary text-white font-bold h-12 w-12 flex justify-center items-center rounded-full m-4"
      onClick={toggle}
    >
      {!playing ? (
        <svg
          width="20"
          height="23"
          viewBox="0 0 20 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.691344 2.34807C0.691343 1.19337 1.94134 0.471688 2.94134 1.04904L18.6913 10.1423C19.6913 10.7197 19.6913 12.163 18.6913 12.7404L2.94134 21.8336C1.94134 22.411 0.691343 21.6893 0.691343 20.5346L0.691344 2.34807Z"
            className="fill-white"
          />
        </svg>
      ) : (
        <svg
          width="18"
          height="24"
          viewBox="0 0 18 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="6" height="24" rx="1.5" className="fill-white" />
          <rect x="12" width="6" height="24" rx="1.5" className="fill-white" />
        </svg>
      )}
    </button>
  );
};

export default Player;
