import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
import ContextWrapper from "./ContextWrapper";

// pages
const App = lazy(() => import("./App"));
const UcapanDanDoa = lazy(() => import("./routes/ucapan-dan-doa"));
const Rsvp = lazy(() => import("./routes/rsvp"));
const ManajemenUcapanDanDoa = lazy(() =>
  import("./routes/manajemen-ucapan-dan-doa")
);
const ManajemenRsvp = lazy(() => import("./routes/manajemen-rsvp"));
const Signin = lazy(() => import("./routes/signin"));
const ProtectedRoute = lazy(() => import("./components/ProtectedRoute"));
const Loading = lazy(() => import("./components/ui/Loading"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Suspense fallback={<Loading />}>
    <ContextWrapper>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/rsvp" element={<Rsvp />} />
          <Route path="/ucapan-dan-doa" element={<UcapanDanDoa />} />
          <Route path="/signin" element={<Signin />} />

          <Route
            exact
            path="/manajemen-ucapan-doa"
            element={<ProtectedRoute />}
          >
            <Route
              exact
              path="/manajemen-ucapan-doa"
              element={<ManajemenUcapanDanDoa />}
            />
          </Route>
          <Route exact path="/manajemen-rsvp" element={<ProtectedRoute />}>
            <Route exact path="/manajemen-rsvp" element={<ManajemenRsvp />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ContextWrapper>
  </Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
