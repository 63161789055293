import React, { createContext, useState } from "react";
import music from "./music/Legend-Of-Mana-A-Soulful-Place-H.mp3";
import Player from "./components/Player";
export const OpenInvitationContext = createContext();

export default function ContextWrapper({ children }) {
  // setting parent state for dynamic context
  const [isInvitationOpened, setIsInvitationOpened] = useState(false);

  return (
    <OpenInvitationContext.Provider
      value={{ isInvitationOpened, setIsInvitationOpened }}
    >
      {children}
      {isInvitationOpened && <Player url={music} />}
    </OpenInvitationContext.Provider>
  );
}
